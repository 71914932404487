import { React, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { styled } from '@mui/material/styles';
import ApplicationAddStep from "./ApplicationAddStep";
import ApplicationAddForm01 from "./ApplicationAddForm01";
import ApplicationAddForm02 from "./ApplicationAddForm02";
import ApplicationAddEnd from "./ApplicationAddEnd";
import ApplicationAddConfirm from "./ApplicationAddConfirm";

const Input = styled('input')({
  display: 'none',
});

export default function ApplicationAdd() {
  let {id} = useParams();

  const { data,dataApplication,getApplication } = useMyData();

  useEffect(()=>{
    console.log("id:" + id );
    getApplication(id);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[data]);


  return (
    <>
      {dataApplication.id && 
      <>
        {dataApplication.mode==='step1' && <ApplicationAddStep />}
        {dataApplication.mode==='step2' && <ApplicationAddStep />}
        {dataApplication.mode==='step3' && <ApplicationAddStep />}
        {dataApplication.mode==='step4' && <ApplicationAddForm01 />}
        {dataApplication.mode==='step5' && <ApplicationAddForm02 />}
        {dataApplication.mode==='step6' && <ApplicationAddConfirm />}
        {dataApplication.mode==='step7' && <ApplicationAddEnd />}
        {dataApplication.mode==='step8' && <ApplicationAddEnd />}
        { (typeof dataApplication === 'undefined' || typeof dataApplication.mode === 'undefined' || dataApplication.mode == 'back' ) && <ApplicationAddStep />}
      </>
      }
    </>
 );

}
