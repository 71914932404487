import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox } from "./hooks";
import { useInView } from 'react-intersection-observer';

export default function SignUpGroupInput() {
  const { setSignupData, getSignupData, getAddressFromZip } = useMyData();
  const [signupData, signupMode] = getSignupData();

  const [r_nameProps, resetRName] = useInput(signupData.r_name||""); // 利用者団体名
  const [r_kanaProps, resetRKana] = useInput(signupData.r_kana||"","kana"); // 利用者団体名カナ
  const [r_d_l_nameProps, resetRDLName] = useInput(signupData.r_d_l_name||""); // 利用者代表者姓
  const [r_d_f_nameProps, resetRDFName] = useInput(signupData.r_d_f_name||""); // 利用者代表者名
  const [r_d_l_kanaProps, resetRDLKana] = useInput(signupData.r_d_l_kana||"","kana"); // 利用者代表者姓カナ
  const [r_d_f_kanaProps, resetRDFKana] = useInput(signupData.r_d_f_kana||"","kana"); // 利用者代表者名カナ
  const [r_t_l_nameProps, resetRTLName] = useInput(signupData.r_t_l_name||""); // 利用者担当者姓
  const [r_t_f_nameProps, resetRTFName] = useInput(signupData.r_t_f_name||""); // 利用者担当者名
  const [r_t_l_kanaProps, resetRTLKana] = useInput(signupData.r_t_l_kana||""); // 利用者担当者姓カナ
  const [r_t_f_kanaProps, resetRTFKana] = useInput(signupData.r_t_f_kana||""); // 利用者担当者名カナ
  const [r_kubunProps, resetRKubun] = useInput(signupData.r_kubun||""); // 利用者連絡区分(1:代表者 2:担当者)
  const [r_zipcodeProps, resetRZipcode] = useInput(signupData.r_zipcode||"","number"); // 利用者郵便番号
  const [r_addressProps, resetAddress, r_addressSetValue] = useInput(signupData.r_address||""); // 利用者住所
  const [r_tel1aProps, reseRTel1a] = useInput(signupData.r_tel1a||"","number"); // 利用者電話番号1a
  const [r_tel1bProps, reseRTel1b] = useInput(signupData.r_tel1b||"","number"); // 利用者電話番号1b
  const [r_tel1cProps, reseRTel1c] = useInput(signupData.r_tel1c||"","number"); // 利用者電話番号1c
  const [r_tel2aProps, reseRTel2a] = useInput(signupData.r_tel2a||"","number"); // 利用者電話番号2a
  const [r_tel2bProps, reseRTel2b] = useInput(signupData.r_tel2b||"","number"); // 利用者電話番号2b
  const [r_tel2cProps, reseRTel2c] = useInput(signupData.r_tel2c||"","number"); // 利用者電話番号2c
  const [r_faxProps, resetRFax] = useInput(signupData.r_fax||"","number"); // 利用者FAX番号
  const [j_nameProps, resetJName] = useInput(signupData.j_name||""); // 事務代行団体名
  const [j_kanaProps, resetJKana] = useInput(signupData.j_kana||""); // 事務代行団体名カナ
  const [j_d_l_nameProps, resetJDLName] = useInput(signupData.j_d_l_name||""); // 事務代行代表者姓
  const [j_d_f_nameProps, resetJDFName] = useInput(signupData.j_d_f_name||""); // 事務代行代表者名
  const [j_d_l_kanaProps, resetJDLKana] = useInput(signupData.j_d_l_kana||"","kana"); // 事務代行代表者姓カナ
  const [j_d_f_kanaProps, resetJDFKana] = useInput(signupData.j_d_f_kana||"","kana"); // 事務代行代表者名カナ
  const [j_t_l_nameProps, resetJTLName] = useInput(signupData.j_t_l_name||""); // 事務代行担当者姓
  const [j_t_f_nameProps, resetJTFName] = useInput(signupData.j_t_f_name||""); // 事務代行担当者名
  const [j_t_l_kanaProps, resetJTLKana] = useInput(signupData.j_t_l_kana||""); // 事務代行担当者姓カナ
  const [j_t_f_kanaProps, resetJTFKana] = useInput(signupData.j_t_f_kana||""); // 事務代行担当者名カナ
  const [j_kubunProps, resetJKubun] = useInput(signupData.j_kubun||""); // 事務代行連絡区分(1:代表者 2:担当者)'
  const [j_emailProps, resetJEmail] = useInput(signupData.j_email||""); // 事務代行メールアドレス
  const [j_zipcodeProps, resetJZipcode] = useInput(signupData.j_zipcode||"","number"); // 事務代行郵便番号
  const [j_addressProps, resetJAddress, j_addressSetValue] = useInput(signupData.j_address||""); // 事務代行住所
  const [j_tel1aProps, resetJTel1a] = useInput(signupData.j_tel1a||"","number"); // 事務代行電話番号1a
  const [j_tel1bProps, resetJTel1b] = useInput(signupData.j_tel1b||"","number"); // 事務代行電話番号1b
  const [j_tel1cProps, resetJTel1c] = useInput(signupData.j_tel1c||"","number"); // 事務代行電話番号1c
  const [j_tel2aProps, resetJTel2a] = useInput(signupData.j_tel2a||"","number"); // 事務代行電話番号2a
  const [j_tel2bProps, resetJTel2b] = useInput(signupData.j_tel2b||"","number"); // 事務代行電話番号2b
  const [j_tel2cProps, resetJTel2c] = useInput(signupData.j_tel2c||"","number"); // 事務代行電話番号2c
  const [j_faxProps, resetJFax] = useInput(signupData.j_fax||"","number"); // 事務代行FAX番号
  const [emailProps, resetEmail] = useInput(signupData.email||""); // Email(ログインID)(利用者メールアドレス)
  const [email1Props, resetEmail1] = useInput(signupData.email1||""); // Email(ログインID)(利用者メールアドレス)
  const [email2Props, resetEmail2] = useInput(signupData.email2||""); // Email(ログインID)(利用者メールアドレス)
  const [passwordProps, resetPassword] = useInput(signupData.password||""); // パスワード
  const [password1Props, resetPassword1] = useInput(signupData.password1||""); // パスワード
  const [agreeProps] = useCheckbox(signupData.agree ? [...signupData.agree] : []); // 同意チェック

  const [mailCheck, setMailCheck] = useState(true); // メールアドレスチェック
  const [passwdCheck1, setPasswdCheck1] = useState(false); // パスワードチェック
  const [passwdCheck2, setPasswdCheck2] = useState(false); // パスワードチェック
  const [passwdCheck3, setPasswdCheck3] = useState(false); // パスワードチェック

  const [hasError, setHasError] = useState(true); // エラーチェック

  // 利用規約の最後の位置が現れたか
  const { ref, inView } = useInView({
    triggerOnce: true, // 最初の一度だけ実行
  });

  // 郵便番号から住所を取得
  useEffect(() => {
    if(signupMode !== "back"){
      getAddressFromZip( r_zipcodeProps.value, r_addressSetValue );
    }
  },[r_zipcodeProps.value]);

  // 担当者郵便番号から住所を取得
  useEffect(() => {
    if(signupMode !== "back"){
      getAddressFromZip( j_zipcodeProps.value, j_addressSetValue );
    }
  },[j_zipcodeProps.value]);

  // メールアドレスチェック
  useEffect(() => {
    if ( emailProps.value !== ''){
      if ( emailProps.value === email1Props.value + '@' + email2Props.value ) {
        setMailCheck(true);
      } else {
        setMailCheck(false);
      }
    } else {
      setMailCheck(true);
    }
  },[emailProps.value, email1Props.value, email2Props.value]);


  // パスワード内容チェック
  useEffect(() => {
    if ( passwordProps.value && passwordProps.value !== ''){
      if ( passwordProps.value.length >= 6 && passwordProps.value.length <= 32 ) {
        if ( passwordProps.value.search(/[0-9]/)>=0 && passwordProps.value.search(/[^0-9]/)>=0 ) {
          setPasswdCheck1(true);
        } else {
          setPasswdCheck1(false);
        }
      } else {
        setPasswdCheck1(false);
      }
    } else {
      setPasswdCheck1(true);
    }

    if ( passwordProps.value && passwordProps.value !== ''
         && email1Props.value && email1Props.value !== ''
    ){
      if ( passwordProps.value.indexOf(email1Props.value)>=0 ) {
        setPasswdCheck3(false);
      } else {
        setPasswdCheck3(true);
      }
    } else {
      setPasswdCheck3(true);
    }

  },[passwordProps.value,email1Props.value]);

  // パスワード一致チェック
  useEffect(() => {
    if ( passwordProps.value !== ''){
      if ( passwordProps.value === password1Props.value ) {
        setPasswdCheck2(true);
      } else {
        setPasswdCheck2(false);
      }
    } else {
      setPasswdCheck2(true);
    }
  },[passwordProps.value, password1Props.value]);

  useEffect(() => {
    setSignupData( {
      ...signupData
    },"" );
},[]);

  // エラーチェック
  useEffect(() => {
    let err = false;

    // 必須チェック
    if(r_nameProps.value==="") err=true;
    if(r_kanaProps.value==="") err=true;
    if(r_d_l_nameProps.value==="") err=true;
    if(r_d_f_nameProps.value==="") err=true;
    if(r_d_l_kanaProps.value==="") err=true;
    if(r_d_f_kanaProps.value==="") err=true;
    if(emailProps.value==="") err=true;
    if(passwordProps.value==="") err=true;
    if(r_zipcodeProps.value==="") err=true;
    if(r_addressProps.value==="") err=true;
    if(r_tel1aProps.value==="") err=true;
    if(r_tel1bProps.value==="") err=true;
    if(r_tel1cProps.value==="") err=true;


    // 条件判定
    if(!mailCheck) err=true;
    if(!passwdCheck1) err=true;
    if(!passwdCheck2) err=true;
    if(!passwdCheck3) err=true;

    setHasError(err);
  });

  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();

    setSignupData( {
      r_name: r_nameProps.value,
      r_kana: r_kanaProps.value,
      r_d_l_name: r_d_l_nameProps.value,
      r_d_f_name: r_d_f_nameProps.value,
      r_d_l_kana: r_d_l_kanaProps.value,
      r_d_f_kana: r_d_f_kanaProps.value,
      r_zipcode: r_zipcodeProps.value,
      r_address: r_addressProps.value,
      r_tel1a: r_tel1aProps.value,
      r_tel1b: r_tel1bProps.value,
      r_tel1c: r_tel1cProps.value,
      r_tel2a: r_tel2aProps.value,
      r_tel2b: r_tel2bProps.value,
      r_tel2c: r_tel2cProps.value,
      j_d_l_name: j_d_l_nameProps.value,
      j_d_f_name: j_d_f_nameProps.value,
      j_d_l_kana: j_d_l_kanaProps.value,
      j_d_f_kana: j_d_f_kanaProps.value,
      j_zipcode: j_zipcodeProps.value,
      j_address: j_addressProps.value,
      j_tel1a: j_tel1aProps.value,
      j_tel1b: j_tel1bProps.value,
      j_tel1c: j_tel1cProps.value,
      email: emailProps.value,
      email1: email1Props.value,
      email2: email2Props.value,
      password: passwordProps.value,
      password1: password1Props.value,
      agree: ["agree"],
    },"confirm" );

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>

      <h1 className="pagetopLabel">利用者登録（団体）</h1>

      <ul className="Progressbar">
        <li className="active"><span>情報の入力</span></li>
        <li><span>入力内容の確認</span></li>
        <li><span>送信・メールの確認</span></li>
        <li><span>申込み完了</span></li>
      </ul>

      <div className="txt">
        <p>利用者様の情報をご入力後、「横浜みなとみらいホール施設利用ウェブシステム利用規約」に同意のうえ、「確認画面へ」ボタンを押してください。
        </p>
      </div>

      <form onSubmit={submit}>

      <table className="Form" summary="#">
      <tbody><tr>
          <th>団体名 <span>※必須</span></th>
          <td><input {...r_nameProps} size="50" className="w_80" type="text" required /></td>
        </tr>
        <tr>
          <th>団体名フリガナ <span>※必須</span></th>
          <td><input {...r_kanaProps} size="50" className="w_80" type="text" required /></td>
        </tr>
        <tr>
          <th>代表者名 <span>※必須</span></th>
          <td>姓<input {...r_d_l_nameProps} size="20" className="w_20" type="text" required /> 名<input {...r_d_f_nameProps} size="20" className="w_20" type="text" required /></td>
        </tr>
        <tr>
          <th>代表者名フリガナ <span>※必須</span></th>
          <td>姓<input {...r_d_l_kanaProps} size="20" type="text" className="w_20" required /> 名<input {...r_d_f_kanaProps} size="20" className="w_20" type="text" required /></td>
        </tr>
        <tr>
          <th>メールアドレス（ID）<span>※必須</span></th>
          <td><input type="email" {...emailProps} size="50" width="100%" className="w_60" required autoComplete="email" pattern="^[0-9a-zA-Z_\.\+\-]+@[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$" /><span>（半角英数字）</span><br /><span>※メールアドレスがIDとして使用されます。団体でお使いになるメールアドレスをご登録ください。</span></td>
        </tr>
        <tr>
          <th>メールアドレス（確認）<span>※必須</span></th>
          <td>
            <input type="text" {...email1Props} size="30" width="100%" className="w_30" required pattern="^[0-9a-zA-Z_\.\+\-]+$" />＠<input type="text" {...email2Props} size="20" width="100%" className="w_20" required pattern="^[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$"/ ><span>（半角英数字）</span><br />
            { !mailCheck  && <span className="notice">メールアドレスが一致しません</span>}
          </td>
        </tr>
        <tr>
          <th>パスワード <span>※必須</span></th>
          <td><input {...passwordProps} size="50" className="w_40" type="password" required pattern="^.{6,32}$" /><span>（半角英字と半角数字を含む、6文字以上32文字以内）</span><br/>
            { !passwdCheck1  && <span className="notice">6文字以上32文字以内で数字とアルファベットを含んで入力ください。</span>}
            { !passwdCheck3  && <span className="notice">このパスワードは使用できません。</span>}
          </td>
        </tr>
        <tr>
          <th>パスワード（確認） <span>※必須</span></th>
          <td><input {...password1Props} size="50" className="w_40" type="password" required pattern="^.{6,32}$" /><span>（半角英字と半角数字を含む、6文字以上32文字以内）</span><br/>
            { !passwdCheck2  && <span className="notice">パスワードが一致しません</span>}
          </td>
        </tr>
        <tr>
          <th>ご住所 <span>※必須</span></th>
          <td><div class="banti-inputarea">
                郵便番号<input type="text" {...r_zipcodeProps} autoComplete="postal-code" className="w_20" required /><span>（半角数字、ハイフンなし）</span>
                <div class="banti-notice"><div><span class="gokakunin">ご確認ください</span><br /><span class="banti">番地、部屋番号</span>までご入力ください。</div></div>
                <input type="text" {...r_addressProps} required size="50" className="w_60" /><br /><span>番地、部屋番号までご入力ください。</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>電話番号1 <span>※必須</span></th>
          <td><input {...r_tel1aProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...r_tel1bProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...r_tel1cProps} size="10" className="w_10" type="text" maxLength="4" required /><span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td><input {...r_tel2aProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_tel2bProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_tel2cProps} size="10" className="w_10" type="text" maxLength="4" /><span>（半角数字）</span></td>
        </tr>
      </tbody>
      </table>

      <h6>担当者情報</h6>

      <p className="font_12">代表者とは別の担当者がいる場合のみ入力してください。</p>

      <table className="Form" summary="#">
      <tbody>
      <tr>
          <th>担当者名</th>
          <td>姓<input size="20" type="text" className="w_40" {...j_d_l_nameProps} /> 名<input size="20" className="w_40" type="text" {...j_d_f_nameProps} /></td>
        </tr>
        <tr>
          <th>担当者名フリガナ</th>
          <td>姓<input size="20" type="text" className="w_40" {...j_d_l_kanaProps} /> 名<input size="20" className="w_40" type="text" {...j_d_f_kanaProps} /></td>
        </tr>
        <tr>
          <th>ご住所</th>
          <td><div class="banti-inputarea">
                郵便番号<input type="text" {...j_zipcodeProps} autoComplete="postal-code" className="w_20" /><span>（半角数字、ハイフンなし）</span>
                <div class="banti-notice"><div><span class="gokakunin">ご確認ください</span><br /><span class="banti">番地、部屋番号</span>までご入力ください。</div></div>
                <input type="text" {...j_addressProps} size="50" className="w_60" /><br /><span>代表者と連絡先が異なる場合のみ入力</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td><input {...j_tel1aProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...j_tel1bProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...j_tel1cProps} size="10" className="w_10" type="text" maxLength="4" /><span>（半角数字）</span><br/>
          <span>代表者と連絡先が異なる場合のみ入力</span></td>
        </tr>
      </tbody>
      </table>

      <p>利用規約を最後までスクロールしてご確認のうえ、「同意する」にチェックを入れてしてください。<br />「確認画面へ」がクリックできるようになります。</p>
      <div className="Terms">
        <strong>横浜みなとみらいホール施設利用ウェブシステム　利用規約</strong><br /><br />
        （趣旨）<br />
        <ul className="kiyaku">
          <li>第1条　横浜みなとみらいホール施設利用ウェブシステム（以下「ウェブシステム」という。）は、横浜みなとみらいホール施設の利用にあたり、インターネットを介したホール公式ウェブサイトからの音楽練習室利用申込み、大小ホール利用抽選申込み、および公演情報のフォーム入力を可能とすることでホール利用者の利便性を高めるとともに、横浜みなとみらいホール貸館運営の効率化を図ることを目的に、横浜みなとみらいホール指定管理者（以下「ホール」という。）が設置するウェブシステムです。この規約は、適正かつ公平なウェブシステム運用を行うため、ウェブシステムの利用に際し必要となる内容について定めるものです。
          </li>
        </ul>
        （規約の承認）<br />
        <ul className="kiyaku">
          <li>
          第2条　ウェブシステムを利用して施設の予約等の手続きを行うためには、本規約への同意が必要となります。ウェブシステムを利用しようとする者（以下「利用者」という）は、本規約に同意したものとみなされ、ホールは規約の同意を前提に利用者へウェブシステムのサービスを提供します。何らかの理由により本規約への同意が確認できない場合は、ウェブシステムをご利用いただけません。
          </li>
        </ul>

        （利用者登録）<br />
        <ul className="kiyaku">
          <li>第3条　本規約に同意のうえ、ウェブシステムの利用に必要な登録申請を、インターネットを介して、または所定の登録申請方法によりホールに対して行い、ホールがその申請を承認して登録した個人または団体の利用者を登録者とします。
          </li>
          <li>　　２&nbsp;登録者は、ホールが登録者の氏名、団体名、住所および電話番号等の情報をウェブシステムおよび横浜みなとみらいホール施設予約管理システム内で管理し、業務上必要な範囲内で利用することを了承するものとします。
          </li>
        </ul>

        （利用者登録の条件）<br />
        <ul className="kiyaku">
          <li>第4条　個人または団体が利用者の登録をしようとする場合は、次の条件を満たすこととします。</li>
          <li>　　(1)&nbsp;同じ個人、または同じ団体がウェブシステムに登録されていないこと</li>
          <li>　　(2)&nbsp;同じIDがウェブシステムに利用者の登録がなされていないこと</li>
          <li>　　２&nbsp;利用者の登録は、1IDにつき、一個人または一団体の登録ができるものとします。</li>
          <li>　　３&nbsp;ホールは、利用者登録を希望する個人または団体が次の各号のいずれかに該当する場合、当該利用者登録の申し込みを承認しない場合があります。</li>
          <li>　　(1)&nbsp;既に利用者登録をしている場合</li>
          <li>　　(2)&nbsp;申込内容が他の利用者の登録情報の全部または一部と同じである場合</li>
          <li>　　(3)&nbsp;申込内容に誤記または記入漏れがあった場合</li>
          <li>　　(4)&nbsp;申込後、ホールが登録案内のメールを送信してから一定期間登録作業を行わない場合</li>
          <li>　　(5)&nbsp;その他、ホールが申込の承認を不適当と判断する場合</li>
        </ul>
        （IDおよびパスワードの設定、管理）<br />
        <ul className="kiyaku">
          <li>第5条　ホールは、登録者が指定する電子メールアドレスを当該登録者に係るID（以下「ID」という。）としてウェブシステムに登録します。</li>
          <li>　　２&nbsp;ホールは、登録者が指定する文字列を当該登録者に係るパスワード（以下「パスワード」という。）としてウェブシステムに登録します。</li>
          <li>　　３&nbsp;登録者は、善良な管理者の注意をもって、IDおよびパスワードを適切に管理するものとします。また特定のIDおよびパスワードにより行われた行為については、その登録者により行われた行為とみなします。</li>
          <li>　　４&nbsp;IDおよびパスワードの使用あるいは管理に際し、登録者に起因する理由で、IDおよびパスワードが不正に使用され、その結果登録者に何らかの不利益が生じたとき、あるいはホール、ウェブシステムまたは横浜みなとみらいホール施設、または他の利用者に損害を与えた場合は、当該登録者がその一切の責めを負うものとします。</li>
        </ul>
        （IDおよびパスワードの紛失、漏洩、盗難）<br />
        <ul className="kiyaku">
          <li>第6条　登録者は、IDおよびパスワードを紛失、あるいは漏洩、または盗まれた場合は、直ちにその旨をホールへ届け出るものとします。この場合において、ホールは、当該IDおよびパスワードの利用を停止することができるものとします。</li>
          <li>　　２&nbsp;前項の届出を行うまでの間において、登録者のIDおよびパスワードによってなされた行為は、登録者によって行われたものとみなします。</li>
          <li>　　３&nbsp;前項の届出を行うまでの間において、当該IDおよびパスワードを第三者に不正に使用され、その結果登録者に何らかの不利益が生じた場合、あるいはホール、ウェブシステム、横浜みなとみらいホール施設、または他の利用者に損害を与えた場合は、当該登録者がその一切の責めを負うものとします。</li>
        </ul>
        （登録情報の字体）<br />
        <ul className="kiyaku">
          <li>第7条　登録者の申請内容に使われる字体が、ウェブシステムにおいて取扱いが困難な字体である場合は、類似する標準字体（JIS 第一、第二水準）で登録するものとします。この場合において、ウェブシステムで表示される字体並びに郵送物等の字体は標準字体となります。</li>
        </ul>
        （登録の廃止）<br />
        <ul className="kiyaku">
          <li>第8条　登録者が所定の方法でウェブシステムの登録廃止の意志を申し出、ホールがこれを承認した場合、当該登録を廃止するものとします。</li>
        </ul>
        （施設利用申込み等）<br />
        <ul className="kiyaku">
          <li>第9条　登録者は、パソコンまたはタブレット、携帯電話等によってインターネットを通じ、ウェブシステムにアクセスし、そのIDおよびパスワードを入力することにより、次のサービスを受けることができます。</li>
          <li>　　(1)&nbsp;音楽練習室の予約(当日の予約を除く)</li>
          <li>　　(2)&nbsp;ホール利用に関わる公演情報の登録･提出</li>
          <li>　　２&nbsp;前項のサービスは、利用期間内24時間の受付を基本としますが、ウェブシステムのメンテナンスのため利用できない日または時間帯が生じる場合があります。</li>
          <li>　　３&nbsp;第1項第1号は、ホールが別途定める件数を上限として同時に複数の予約ができるものとします。</li>
        </ul>
        （音楽練習室利用予約の取消）<br />
        <ul className="kiyaku">
          <li>第10条	登録者は、第9条第１項第１号に規定する音楽練習室の予約について、登録者のやむを得ない都合により予約の取消を希望する場合は、事前に所定の方法で取消を申し出、ホールがこれを承認した場合、当該予約を取消できるものとします。</li>
        </ul>
        （ウェブシステム登録事項の変更）<br />
        <ul className="kiyaku">
          <li>第11条	登録者は、ウェブシステムに登録した氏名、住所等に変更が生じた場合は、遅滞なく、インターネットまたは所定の方法により登録事項の変更手続を行うものとします。</li>
          <li>　　２&nbsp;前項の手続がないために、ホールからの通知または送付書類その他のものが延着または到着しなかったことにより生じる不利益または損害について、ホールは責任を負わないものとします。</li>
        </ul>
        （登録者の利用停止および取消）<br />
        <ul className="kiyaku">
          <li>第12条	ホールは、登録者または登録団体が次のいずれかに該当した場合には、ウェブシステムの利用を停止し、またはその登録を取り消すことができるものとします。また停止、もしくは取消された場合に生じる不利益や損害について、ホールは責任を負わないものとします。</li>
          <li>　　(1)&nbsp;ウェブシステムの登録や利用において虚偽の申告をした場合</li>
          <li>　　(2)&nbsp;本規約に違反した場合</li>
          <li>　　(3)&nbsp;第11条第1項に規定する手続がされない場合</li>
          <li>　　(4)&nbsp;登録者の責に帰すべき事由によりその所在が不明となり、当該登録者への通知・連絡が不可能であるとホールが判断した場合</li>
          <li>　　(5)&nbsp;ウェブシステム対象施設が規定されている条例および条例規則等に違反した場合</li>
          <li>　　(6)&nbsp;ウェブシステムおよび施設の利用に際し、ホールの指示に従わない場合</li>
          <li>　　(7)&nbsp;不適切な利用方法により他の利用者の利用の妨げになるとホールが判断した場合</li>
          <li>　　(8)&nbsp;第10条に規定する音楽練習室予約の取消回数が、ホールが別途定める回数を超えた場合</li>
          <li>　　(9)&nbsp;第10条に規定する音楽練習室予約の取消を行わず、予約当日ホールに来館せず、音楽練習室の利用を行わない回数が、ホールが別途定める回数を超えた場合</li>
          <li>　&nbsp;(10)&nbsp;ウェブシステムおよび施設の管理･運営上支障があるとき、そのほかホールが必要と認めたとき</li>
        </ul>
        （禁止行為）<br />
        <ul className="kiyaku">
          <li>第13条	ウェブシステムの利用に当たっては、次に掲げる行為を禁止します。</li>
          <li>　　(1)&nbsp;ウェブシステムを施設予約以外の目的で利用すること</li>
          <li>　　(2)&nbsp;ウェブシステムの管理および運営を妨害、破壊すること</li>
          <li>　　(3)&nbsp;ウェブシステムに対し、不正にアクセスすること</li>
          <li>　　(4)&nbsp;ウェブシステムに対し、ウィルスに感染したファイルや、悪意のあるプログラムを含むファイルを送信すること</li>
          <li>　　(5)&nbsp;ウェブシステム、ウェブシステムに含まれるプログラム、およびその他著作物の修正、複製、改ざん、販売等の行為</li>
          <li>　　(6)&nbsp;他人もしくは他団体のID、パスワード等を不正に使用すること。</li>
          <li>　　(7)&nbsp;その他法令等に違反すると認められる行為をすること</li>
          <li>　　２&nbsp;ホールは、ウェブシステムに対し前項(1)から(6)のいずれかに該当する行為が明らかな場合、又は該当する行為があると疑うに足りる相当な理由がある場合は、利用者から収集した情報の抹消、ウェブシステムの停止等の必要な措置を行うことができるものとします。</li>
          </ul>
          （利用の中止、中断、変更など）<br />
          <ul className="kiyaku">
          <li>第14条 ホールは次の事項に該当する場合、利用者に事前に通知することなく、本利用を中止、中断、変更、停止することができます。</li>
          <li>　　(1)&nbsp;ウェブシステムの保守を定期的または緊急に行う場合</li>
          <li>　　(2)&nbsp;天災地変・戦争・内乱・交通機関の事故及びゼネスト・法律命令・行政措置等のうち、不可抗力と評価されるものにより止むを得ず本システムの利用の提供が通常通りできない場合</li>
            <li>　　(3)&nbsp;その他、ホールが本システムの運営上、中止、中断、変更、停止が必要であると判断した場合</li>
          </ul>
        （免責事項）<br />
        <ul className="kiyaku">
          <li>第15条	次の各項目に対してホールはその一切の責任を負いません。</li>
          <li>　　(1)&nbsp;利用者が本ウェブシステムを利用したことにより発生した利用者の損害、および利用者が第三者に与えた損害</li>
          <li>　　(2)&nbsp;第14条に規定する理由により、利用者への事前の通告の有無を問わず、ホールがその裁量において行った、ウェブシステムの改修、運用停止、中断等のために生じた利用者の損害</li>
          <li>　　(3)&nbsp;ウェブシステムの利用者が使用するパソコンまたはタブレット、携帯電話等機器の障害、不具合、通信回線上の障害、その他、ホールの責めに帰さない理由によるウェブシステムの障害等により発生した利用者の損害、および、利用者が第三者に与えた損害</li>
        </ul>
        （著作権）<br />
        <ul className="kiyaku">
          <li>第16条	本ウェブシステムに含まれているプログラムおよびその他著作物に関する著作権は、ホールに帰属します。</li>
        </ul>
        （個人情報の保護）<br />
        <ul className="kiyaku">
          <li>第17条	ウェブシステムおよびウェブシステム対象施設の利用のために提供を受けた個人情報は、「公益財団法人横浜市芸術文化振興財団プライバシーポリシー」（https://p.yafjp.org/privacy_policy/）に則り、適切に取扱います。</li>
          <li>　　２&nbsp;個人情報の収集・利用・管理は、ホールのほか、ウェブシステムおよび横浜みなとみらいホール施設予約管理システムの管理･運営に関わる受託業者が行います。</li>
          <li>　　３&nbsp;ウェブシステムの利用・運用、対象施設の利用・運営、およびそれに伴う予約管理業務（料金収納業務や登録者から提供された公演情報を基に行う広報業務を含む）以外の目的で、登録者の同意を得ずに個人情報の利用、または第三者への情報提供を行うことはありません。ただし、次のいずれかに該当する場合を除きます。</li>
          <li>　　(1)&nbsp;裁判所・警察等法執行機関等から、法令に基づき情報の開示を求められた場合</li>
          <li>　　(2)&nbsp;人の生命、身体または財産を保護するため、緊急かつやむを得ないとホールが判断した場合</li>
        </ul>
        （規約の変更、承認）<br />
        <ul className="kiyaku">
          <li>第18条	ホールは、必要があると認めるときは、利用者への事前の通知を行うことなく、本規約を変更することができるものとします。</li>
          <li>　　２&nbsp;本規約の変更後にウェブシステムを利用したときは、利用者は変更後の規約に同意したものとみなします。</li>
        </ul>
        附　則  <br />
        （施行期日） <br />
        この規約は、令和4年7月1日から施行する。 <br />
        附　則  <br />
        （施行期日） <br />
        この規約は、令和4年11月11日から施行する。<br />
        附　則  <br />
        （施行期日） <br />
        この規約は、令和6年5月31日から施行する。<br />

        <div id="end" ref={ref}></div>
      </div>

      <p className="Terms__check"><label><input type="checkbox" checked={agreeProps.val.includes("agree") ? "checked" : ""} value="agree" {...agreeProps} disabled={!inView} />&nbsp;利用規約に同意する</label></p><br/>

      <div className="Form__btnbox">
        <div className="Form__btn-confirm">
          {hasError && <><span className="notice">入力内容を確認ください。</span><br/></>}
        <input value="確認画面へ" type="submit" id="next" disabled={!agreeProps.val.includes("agree") || hasError } />
        </div>
      </div>
    </form>
  </>
 );
}
