import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import NewsItem from "./NewsItem";


export default function TopMain() {
  const { data, dataConcerts, getConcerts, setRoom, news, getNews } = useMyData();

  const [topNews, setTopNews] = useState([]);


  useEffect(() => {
    //getConcerts();
    setRoom(0);
  }, [data]);

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    if (news.length > 0) {
      const [top1, ...others] = news;
      others.sort((a, b) => {
        return b.sortdate - a.sortdate
      });
      const top10 = others.slice(0, 10);
      setTopNews([top1, ...top10])
    }
  }, [news]);

  const d = new Date();
  const nowdate = `${d.getFullYear()}${(d.getMonth()+1).toString().padStart(2, '0')}${d.getDate().toString().padStart(2, '0')}${d.getHours().toString().padStart(2, '0')}${d.getMinutes().toString().padStart(2, '0')}`
                  .replace(/\n|\r/g, '');

  return (
    <>
      <h1 className="pagetopLabel">マイページ</h1>
      {topNews.length > 0 &&
        <div className="Mypagenews">
          <p className="Mypagenews__title">ホールからのお知らせ</p>
          <ul className="Mypagenews__list">
            {topNews.map((item) => (
              <li key={item.id}>
                <NewsItem item={item} />
              </li>
            ))}
          </ul>
          <div className="Mypagenews__btn">
            <Link to={"news"}>一覧へ</Link>
          </div>
        </div>
      }


      {process.env.REACT_APP_PREOPEN >= 3 ? // プレオープン3用
        <>
        {( nowdate >= process.env.REACT_APP_CLOSE_START && nowdate <= process.env.REACT_APP_CLOSE_END ) ? // CLOSE期間
        <div className="l-row">
          <div className="l-triColumn Mypagebtn">
            <a href="#" className="disable" disabled>
              <div><img src="./images/system/s-reserve01.svg" alt="" /></div>
              <p>音楽練習室予約</p>
            </a>
          </div>
          <div className="l-triColumn Mypagebtn">
            <a href="#" className="disable" disabled>
              <div><img src="./images/system/s-pr.svg" alt="" /></div>
              <p>公演情報登録</p>
            </a>
          </div>
          <div className="l-triColumn Mypagebtn">
            <a href="#" className="disable" disabled>
              <div><img src="./images/system/s-profile.svg" alt="" /></div>
              <p>利用者情報 確認･変更</p>
            </a>
          </div>
          <div className="l-triColumn Mypagebtn">
            <a href="#" className="disable" disabled>
              <div><img src="./images/system/s-reserve02.svg" alt="" /></div>
              <p>音楽練習室予約履歴</p>
            </a>
          </div>
        </div>
        :
        <div className="l-row">
          <div className="l-triColumn Mypagebtn">
            <Link to='/reserve'>
              <div><img src="./images/system/s-reserve01.svg" alt="" /></div>
              <p>音楽練習室予約</p>
            </Link>
          </div>
          <div className="l-triColumn Mypagebtn">
            {false &&
              <Link to={dataConcerts.concerts && dataConcerts.concerts.length > 0 ? '/concerts' : '#'} className={dataConcerts.concerts && dataConcerts.concerts.length > 0 ? '' : 'disable'}>
                <div><img src="./images/system/s-pr.svg" alt="" /></div>
                <p>公演情報登録</p>
              </Link>
            }
            <Link to='/concerts'>
              <div><img src="./images/system/s-pr.svg" alt="" /></div>
              <p>公演情報登録</p>
            </Link>
          </div>
          <div className="l-triColumn Mypagebtn">
            <Link to='/info'>
              <div><img src="./images/system/s-profile.svg" alt="" /></div>
              <p>利用者情報 確認･変更</p>
            </Link>
          </div>
          <div className="l-triColumn Mypagebtn">
            <Link to='/reserve_log'>
              <div><img src="./images/system/s-reserve02.svg" alt="" /></div>
              <p>音楽練習室予約履歴</p>
            </Link>
          </div>
        </div>
        }
        </>

        : process.env.REACT_APP_PREOPEN >= 2 ? // プレオープン2用
          <div className="l-row">
            <div className="l-triColumn Mypagebtn">
              <Link to='/reserve'>
                <div><img src="./images/system/s-reserve01.svg" alt="" /></div>
                <p>音楽練習室予約</p>
              </Link>
            </div>
            <div className="l-triColumn Mypagebtn">
              <Link to='/reserve_log'>
                <div><img src="./images/system/s-reserve02.svg" alt="" /></div>
                <p>音楽練習室予約履歴</p>
              </Link>
            </div>
            <div className="l-triColumn Mypagebtn">
              <Link to='/info'>
                <div><img src="./images/system/s-profile.svg" alt="" /></div>
                <p>利用者情報 確認･変更</p>
              </Link>
            </div>

          </div>
          : process.env.REACT_APP_PREOPEN >= 1 ? // プレオープン1用
            <div className="l-row">
              <div className="l-triColumn Mypagebtn">
                <a href="#" className="disable" disabled>
                  <div><img src="./images/system/s-reserve01.svg" alt="" /></div>
                  <p>音楽練習室予約</p>
                </a>
              </div>
              <div className="l-triColumn Mypagebtn">
                <a href="#" className="disable" disabled>
                  <div><img src="./images/system/s-reserve02.svg" alt="" /></div>
                  <p>音楽練習室予約履歴</p>
                </a>
              </div>
              <div className="l-triColumn Mypagebtn">
                <Link to='/info'>
                  <div><img src="./images/system/s-profile.svg" alt="" /></div>
                  <p>利用者情報 確認･変更</p>
                </Link>
              </div>

            </div>
          : ( nowdate >= process.env.REACT_APP_CLOSE_START && nowdate <= process.env.REACT_APP_CLOSE_END ) ? // CLOSE期間
              <div className="l-row">
              <div className="l-triColumn Mypagebtn">
                <a href="#" className="disable" disabled>
                  <div><img src="./images/system/s-reserve01.svg" alt="" /></div>
                  <p>音楽練習室予約</p>
                </a>
              </div>
              <div className="l-triColumn Mypagebtn">
                <a href="#" className="disable" disabled>
                  <div><img src="./images/system/s-hall01.svg" alt="" /></div>
                  <p>大小ホール抽選申込</p>
                </a>
              </div>
              <div className="l-triColumn Mypagebtn">
                <a href="#" className="disable" disabled>
                  <div><img src="./images/system/s-pr.svg" alt="" /></div>
                  <p>公演情報登録</p>
                </a>
              </div>
              <div className="l-triColumn Mypagebtn">
                <a href="#" className="disable" disabled>
                  <div><img src="./images/system/s-reserve02.svg" alt="" /></div>
                  <p>音楽練習室予約履歴</p>
                </a>
              </div>
              <div className="l-triColumn Mypagebtn">
                <a href="#" className="disable" disabled>
                  <div><img src="./images/system/s-hall02.svg" alt="" /></div>
                  <p>大小ホール抽選履歴</p>
                </a>
              </div>
              <div className="l-triColumn Mypagebtn">
                <a href="#" className="disable" disabled>
                  <div><img src="./images/system/s-profile.svg" alt="" /></div>
                  <p>利用者情報 確認･変更</p>
                </a>
              </div>

            </div>
          : // 本公開用
            <div className="l-row">
              <div className="l-triColumn Mypagebtn">
                <Link to='/reserve'>
                  <div><img src="./images/system/s-reserve01.svg" alt="" /></div>
                  <p>音楽練習室予約</p>
                </Link>
              </div>
              <div className="l-triColumn Mypagebtn">
                <Link to='/apply'>
                  <div><img src="./images/system/s-hall01.svg" alt="" /></div>
                  <p>大小ホール抽選申込</p>
                </Link>
              </div>
              <div className="l-triColumn Mypagebtn">
                {false &&
                  <Link to={dataConcerts.concerts && dataConcerts.concerts.length > 0 ? '/concerts' : '#'} className={dataConcerts.concerts && dataConcerts.concerts.length > 0 ? '' : 'disable'}>
                    <div><img src="./images/system/s-pr.svg" alt="" /></div>
                    <p>公演情報登録</p>
                  </Link>
                }
                <Link to='/concerts'>
                  <div><img src="./images/system/s-pr.svg" alt="" /></div>
                  <p>公演情報登録</p>
                </Link>
              </div>
              <div className="l-triColumn Mypagebtn">
                <Link to='/reserve_log'>
                  <div><img src="./images/system/s-reserve02.svg" alt="" /></div>
                  <p>音楽練習室予約履歴</p>
                </Link>
              </div>
              <div className="l-triColumn Mypagebtn">
                <a href="#">
                  <div><img src="./images/system/s-hall02.svg" alt="" /></div>
                  <p>大小ホール抽選履歴</p>
                </a>
              </div>
              <div className="l-triColumn Mypagebtn">
                <Link to='/info'>
                  <div><img src="./images/system/s-profile.svg" alt="" /></div>
                  <p>利用者情報 確認･変更</p>
                </Link>
              </div>

            </div>
      }
    </>
  );
}
