import React, { createContext, useState, useContext } from "react";
import { v4 } from "uuid";

const MyContext = createContext();
export const useMyData = () => useContext(MyContext);

export default function MyProvider({ children }) {
  const [data, setData] = useState({});
  const [dataMy, setDataMy] = useState({});
  const [dataConcert, setDataConcert] = useState({});
  const [dataConcerts, setDataConcerts] = useState({});
  const [dataPlayerParts, setDataPlayerParts] = useState({});
  const [dataConcertPr, setDataConcertPr] = useState({});
  const [dataApplication, setDataApplication] = useState({});
  const [dataApplications, setDataApplications] = useState({});
  const [room, setRoom] = useState(0);
  const [dataPractice, setDataPractice] = useState({});
  const [dataPracticeRsv, setDataPracticeRsv] = useState({});
  const [dataPracticeSpDay, setDataPracticeSpDay] = useState();

  const [news, setNews] = useState([]);
  const [newsRead, setNewsRead] = useState([]);


  // APIを叩いて郵便番号から住所を取得
  const getAddressFromZip = async (zip,target) => {
    if(zip && zip.length===7){
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      try {
        const response = await fetch(
          API_ENDPOINT + 'kenall/' + zip
        );
        const data = await response.json();
        if(data && data.addr) {
          target(data.addr);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const setSignupData = (signupData,signupMode) => {
    setData({
      ...data,
      signupData,
      signupMode
    })
  }

  const getSignupData = () => {
    return [
      data.signupData ? data.signupData : {},
      data.signupMode ? data.signupMode : ""
    ];
  }

  const loadJSON = (key) => {
    if(key){
    } else {
      return {};
    }
    const data = localStorage.getItem(key);
    if(typeof data === "undefined" || data === "undefined") {
      return {};
    } else {
      return JSON.parse(data)
    }
  };
  const saveJSON = (key, data) => localStorage.setItem(key, JSON.stringify(data));

  // APIを叩いてパスワード再設定
  const updatePasswd = async (password, uuid, forgot, mail, target) => {
    if(mail){
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      try {
        const response = await fetch(
          API_ENDPOINT + 'updatepw',
          {
            headers: {
              'Content-type':'application/json'
            },
            method: "post",
            body: JSON.stringify({ password, uuid, forgot, mail }),
            cache: "no-cache",
          }
        );
        const data = await response.json();
        if(data) {
          if ( data.status ) {
            target(data.status);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }


  // APIを叩いてパスワード忘れ
  const forgot = async (mail, target) => {
    if(mail){
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      try {
        const response = await fetch(
          API_ENDPOINT + 'forgot',
          {
            headers: {
              'Content-type':'application/json'
            },
            method: "post",
            body: JSON.stringify({ mail }),
            cache: "no-cache",
          }
        );
        const data = await response.json();
        if(data) {
          if ( data.status ) {
            target(data.status);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // APIを叩いてログイン
  const myAuth = async (id,pw,target) => {
    if(id && pw){
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      try {
        const response = await fetch(
          API_ENDPOINT + 'signin',
          {
            headers: {
              'Content-type':'application/json'
            },
            method: "post",
            body: JSON.stringify({id, pw }),
            cache: "no-cache",
          }
        );
        const data = await response.json();
        if(data) {
          if ( data.status ) {
            target(data.status);
          }
          if ( data.token ) {
            saveJSON("token",data.token);
            saveJSON("loading",0);
            setData({
              ...data,
              "token": data.token,
            })
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // ログアウト
  const signOut = (redirect=1) => {
    setData({
      ...data,
      "token": undefined,
      "name": undefined,
    });
    saveJSON("token",undefined);
    if(redirect){
      window.location = "/";
    }
  }

  // TOKENが有効なのか確認
  const authCheck = async () => {
    const token = loadJSON("token");

    const loading = loadJSON("loading");
    if(loading==1){
      return;
    }
    saveJSON("loading",1);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    setData({
      ...data,
      "loading": true,
    });
    try {
      const response = await fetch(
        API_ENDPOINT + 'authcheck',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      saveJSON("loading",0);
      if(d) {
        if ( d.status ) {
          if ( d.status === "OK" && d.token ) {
            saveJSON("token",d.token);
            setData({
              ...data,
              "loading": false,
              "token": d.token,
              "name": d.name,
              "lastlogin": d.lastlogin,
            })
          } else {
            setData({
              ...data,
              "token": undefined,
              "name": undefined,
              "lastlogin": undefined,
            })
          }
        } else {
          signOut(0);
        }
      } else {
        signOut(1);
      }
    } catch (error) {
      console.log(error);
      saveJSON("loading",0);
    }
  }

  // APIを叩いて認証メール再送
  const mailAuth = async (mail) => {
    if(mail){
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      try {
        const response = await fetch(
          API_ENDPOINT + 'mailauthagain',
          {
            headers: {
              'Content-type':'application/json'
            },
            method: "post",
            body: JSON.stringify({ mail }),
            cache: "no-cache",
          }
        );
        const data = await response.json();
        if(data) {
          console.log('send');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // 公演情報一覧取得
  const  getConcerts = async () => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/concerts',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        setDataConcerts({
          ...dataConcerts,
          "concerts": d.concerts,
          "concertprs": d.concertprs,
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 公演情報取得
  const  getConcert = async (v4) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/concert/' + v4,
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        setDataConcert({
          ...d.concert,
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 公演情報情報登録
  const  addConcertPr = async (v4, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/concert/' + v4 + '/add',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "POST",
          cache: "no-cache",
          body: JSON.stringify(dataConcertPr),
        }
      );
      const d = await response.json();
      if(d) {
        if ( d.status === "OK" ) {
          target(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 公演情報取得
  const  getConcertPr = async (v4, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/concertpr/' + v4,
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        if ( d.status === "OK" ) {
          setDataConcertPr(d.data);
          target(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  // 公演情報出演者パート一覧取得
  const  getConcertPlayerParts = async () => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/playerparts',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        setDataPlayerParts({
          "parts": d.parts,
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 公演情報画像Upload
  const postConcertImage = async (v4,file,setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const formData = new FormData();
    formData.append('File', file);

    try {
      const response = await fetch(
        API_ENDPOINT + 'my/concert/' + v4 + '/image',
        {
          headers: {
            'X-Token':token
          },
          method: "POST",
          cache: "no-cache",
          body: formData,
        }
      );
      const d = await response.json();
      if(d) {
        if ( d.status === 'OK' ) {
          setHangler(d.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 大小ホール申込情報一覧取得
  const  getApplications = async () => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/applications',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        setDataApplications({
          ...dataApplications,
          "applications": d.applications,
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 大小ホール申込情報取得
  const  getApplication = async (v4) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/application/' + v4,
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        setDataApplication({
          ...d.application,
          step1:{},
          step2:{},
          step3:{},
          req1:[],
          req2:[],
          req3:[],
          apply:{},
          plan:{},
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 大小ホール申込情報登録
  const  addApplication = async (v4, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/application/' + v4 + '/add',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "POST",
          cache: "no-cache",
          body: JSON.stringify(dataApplication),
        }
      );
      const d = await response.json();
      if(d) {
        if ( d.status === "OK" ) {
          target(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 大小ホール申込情報ファイルUpload
  const postApplicationFile = async (v4,file,setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const formData = new FormData();
    formData.append('File', file);

    try {
      const response = await fetch(
        API_ENDPOINT + 'my/application/' + v4 + '/file',
        {
          headers: {
            'X-Token':token
          },
          method: "POST",
          cache: "no-cache",
          body: formData,
        }
      );
      const d = await response.json();
      if(d) {
        if ( d.status === 'OK' ) {
          setHangler(d.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 練習室空き情報取得
  const  getPractice = async (ym) => {
    let req = [];
    let reqFix = [];
    if(ym && ym.match(/^\d{4}\d{2}$/)){
      req = dataPractice.req || [];
      reqFix = dataPractice.reqFix || [];
    } else {
      let today = new Date();
      today.setHours( today.getHours() - 9); // 9時から予約開始なので、カレンダーの表示開始も9時間ずらす
      today.setDate( today.getDate() + 1);
      // console.log("today");
      // console.log(today);
      let todaym = today.getMonth() + 1;
      if(todaym < 10) {
        todaym = '0' + todaym;
      }
      ym = today.getFullYear() + '' + todaym;
    }
    setDataPractice({
      ...dataPractice,
       loading:true,
       mode:'back',
    });

    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/practice/' + ym,
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        let ymdata = {};
        ymdata[ym] = d.practice;
        setDataPractice({
          ...dataPractice,
          mode:'back',
          loading:false,
          ban:d.ban,
          alert:d.alert,
          ym,
          req,
          reqFix,
          ...ymdata,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 練習室定員取得
  const  getPracticeCapacity = async () => {

    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/practicecapacity',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        console.log('capacity');
        console.log(d);
        setDataPractice({
          ...dataPractice,
          capacity:d.capacity,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 練習室空 仮予約
  const  setPracticePreRsv = async () => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/practice/prersv/',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "POST",
          body: JSON.stringify({ req: dataPractice.req }),
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        if(d.status === 'NG') {
          getPractice(dataPractice.ym);
          alert('予約を確保できませんでした');
        }
        if(d.status === 'OK') {
          setDataPractice({
            ...dataPractice,
            reqFix: dataPractice.req,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 練習室空 仮予約解除
  const  resetPracticePreRsv = async () => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/practice/prersvcxl/',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        if(d.status === 'NG') {
          getPractice(dataPractice.ym);
          alert('確保枠解放に失敗しました。');
        }
        if(d.status === 'OK') {
          setDataPractice({
            ...dataPractice,
            reqFix:[],
            req:[],
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 練習室空 本予約
  const  setPracticeRsv = async () => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/practice/rsv/',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "POST",
          body: JSON.stringify({ req: dataPractice.reqFix, form: dataPractice.form }),
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        if(d.status === 'NG') {
          getPractice(dataPractice.ym);
          alert('予約できませんでした');
        }
        if(d.status === 'OK') {
          setDataPractice({
            ...dataPractice,
            mode:'fin',
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 練習室予約取得
  const  getReserves = async () => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/practice/log/',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        setDataPracticeRsv({
          data: d.data,
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  // 練習室予約キャンセル
  const  cancelReserve = async (id) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my/practice/cancel/',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "POST",
          body: JSON.stringify({ id }),
          cache: "no-cache",
        }
      );
      const d = await response.json();
      if(d) {
        setDataPracticeRsv({
          data: d.data,
        })
      }
    } catch (error) {
      console.log(error);
    }
  }



  // APIを叩いて自分の情報を取得
  const myInfo = async () => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'my',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const my = await response.json();
      if(my) {
        setDataMy(
          my
        )
      }
    } catch (error) {
      console.log(error);
    }
  }

  // NEWS情報取得
  const  getNews = async () => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const NEWS_ENDPOINT = process.env.REACT_APP_NEWS_JSON;
    try {
      const response = await fetch(
        API_ENDPOINT + 'news_read',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const news_read = await response.json();
      if(news_read) {
        setNewsRead(news_read.read);
      }

      const newsres = await fetch(
        NEWS_ENDPOINT,
        {
          method: "GET",
          //cache: "no-cache",
        }
      );
      const d = await newsres.json();
      if(d) {
        const dnews = d.news.map (item =>{
          if( news_read.read.includes(Number(item.id)) || news_read.read.includes(item.id) ) {
            return {
              ...item,
              read: 1,
            }
          } else {
            return {
              ...item,
              read: 0,
            }
          }
        });
        setNews(dnews);
      }
    } catch (error) {
      console.log(error);
    }
  }

    // NEWS既読情報送信
  const readNews = async (id) => {
    console.log("readNews:" + id);
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'news_read/' + id,
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const ret = await response.json();
      if(ret) {
        const dnews = news.map (item =>{
          if( item.id==id  ) {
            return {
              ...item,
              read: 1,
            }
          } else {
            return {
              ...item,
            }
          }
        });
        setNews(dnews);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const updateInfo = async (target,type) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [signupData, signupMode] = getSignupData();

    try {
      const response = await fetch(
        API_ENDPOINT + 'my/update',
        {
          headers: {
            'Content-type':'application/json',
            'X-Token':token
          },
          method: "POST",
          body: JSON.stringify({...signupData, type }),  // type:1 団体
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if(data && data.status) {
        console.log(data);

        target(true);
        setSignupData( {
        },"send" );

      }
    } catch (error) {
      console.log(error);
    }
  }




  return (
    <MyContext.Provider value={{
      data,
      dataMy,
      dataConcert,
      dataConcerts,
      dataPlayerParts,
      dataConcertPr,
      setDataConcertPr,
      setDataApplication,

      setSignupData,
      getSignupData,
      getAddressFromZip,
      myAuth,
      myInfo,
      forgot,
      mailAuth,
      updatePasswd,
      authCheck,
      signOut,
      getConcerts,
      getConcert,
      getConcertPlayerParts,
      postConcertImage,
      addConcertPr,
      getConcertPr,
      dataApplications,
      dataApplication,
      getApplications,
      getApplication,
      addApplication,
      postApplicationFile,
      getPractice,
      getPracticeCapacity,
      dataPractice,
      setDataPractice,
      setPracticePreRsv,
      resetPracticePreRsv,
      setPracticeRsv,
      getReserves,
      cancelReserve,
      dataPracticeRsv,
      dataPracticeSpDay,
      setDataPracticeSpDay,

      room,
      setRoom,

      news,
      getNews,
      readNews,
      updateInfo,
    }}>
      {children}
    </MyContext.Provider>
  );
}
